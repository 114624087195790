/* global Sentry */

// Debug info to send to Sentry. Please use and import separate files if you
// need any other code in this bundle.
// utils
// This must be at the very top!
import 'utils.js';
// And this must be right after it!
import 'feature-detect.js';
import 'browser-detection';
import 'device.js/';
import 'detect-device.js';

// Custom observers
import 'fonts-observer-init';

// Polyfills
// TODO: make loading them conditional
import 'helpers/object.assign.js';
import 'helpers/matches.closest.js';
import 'unfetch/polyfill/index.js';

Sentry.onLoad(() => {
  Sentry.setExtra('head-sync.js has run', true);
});
