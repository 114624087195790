/* global mediaUtils */

// -----------------------------------------------------------------------
// Detect certain browser features
// -----------------------------------------------------------------------

// WARNING. This module has side effects, so don't import it more than once in
// a bundle. All the objects are being exported into window here.

// Sometimes the webFeatures global object is not defined. Setting an extra
// breadcrumb here so that when the error occured we could see if this code
// had run at all.
if (window.Sentry) {
  window.Sentry.onLoad(() => {
    window.Sentry.setExtra('webfeautres_fired_start', true);
  });
}

const html = document.documentElement;
const webFeatures = {
  // Will be like: `browser: { chrome: [0, 28] }` (Chrome version 28-)
  browser: null,
};
const prefix = 'moz Moz webkit ms o'.split(' ');
const $test = document.createElement('div');
const $style = window.getComputedStyle($test);
let idPrefix;
const isChrome = !!window.chrome && !!window.chrome.webstore; // Chrome 14+
const isSafari = /constructor/i.test(window.HTMLElement); // Safari All
const isOperaBlink = !isChrome && !isSafari &&
  ('WebkitAppearance' in html.style);
// All IE (IE 9- || IE 10+)
const isIE = document.all && !window.atob ||
  window.navigator.msPointerEnabled;

html.classList.remove('is-noJs');

// We're loading the fonts asyncronousely only if JS is enabled and have
// a standard link inside a <noscript>. So, with our Remove Class FOIT-fighting
// pattern, it's only natural to have this removable class only if JS is on
// as well.
html.classList.add('is-fontsNotLoaded');

if (mediaUtils.checkCookie('is_no_jq') === '1') {
  html.classList.add('is-noJq');
}

/**
 * Check whether a CSS-property works
 *
 * prop <str>       property name
 * prefixed <bool>  use prefixes?
 *
 * return <bool>
 */
function testCSS(prop, prefixed) {
  let ucProp;
  let props;
  let $prop;
  if (prefixed) {
    ucProp = prop.charAt(0).toUpperCase() + prop.slice(1);
    props = (`${prop} ${prefix.join(`${ucProp} `)}${ucProp}`).split(' ');

    for ($prop in props) {
      if ($style[props[$prop]] !== window.undefined) {
        return true;
      }
    }
    return false;
  }
  return $style[prop] !== window.undefined;
}

/**
 * A function to test if certain style is working as expected
 * Can be used for testing animations, browser hacks, etc.
 *
 * @param {String} rule - CSS string, e.g. '@-moz-keyframes color { ... }'
 * @param {Function} callback(div, rule) - A function that will be called
 *    when the test element and styles are injected
 *    {Node} div - the test element
 *    {String} rule - the `rule` param from above
 *    Should return Boolean, based on that this function will return
 * @param {Number} nodes - (optional) number of descendant els to inject
 *    inside the test element
 * @param {Array of String} testnames - (optional) the array of IDs for
 *    test el's descendants. If missing, idPrefix global var value will be
 *    used
 *
 * @return {Boolean} true, if the CSS string worked (based on callback's
 *    returned value)
 */
function injectElementWithStyles(rule, callback, nodes, testnames) {
  let node;
  let docOverflow;
  // The test element, to which we'll be applying the styles
  const div = document.createElement('div');
  const body = document.body;
  // If <body>'s not yet created, make a temporal fake body element
  const fakeBody = body || document.createElement('body');
  let i = nodes;

  // If the test el needs descendants
  if (parseInt(nodes, 10)) {
    while (i) {
      node = document.createElement('div');
      // their IDs
      node.id = testnames ? testnames[i] : idPrefix + (i + 1);
      div.appendChild(node);
      i -= 1;
    }
  }

  const style = ['&#173;', '<style id="s', idPrefix, '">', rule, '</style>']
    .join('');
  div.id = idPrefix;
  if (!body) {
    div.innerHTML += style;
  } else { fakeBody.innerHTML += style; }
  fakeBody.appendChild(div);
  // Adding the fake body (if the real one is not there yet)
  if (!body) {
    fakeBody.style.background = '';
    fakeBody.style.overflow = 'hidden';
    docOverflow = html.style.overflow;
    // Apparently to avoid sudden jumps while adding/deleting test elements
    html.style.overflow = 'hidden';
    html.appendChild(fakeBody);
  }

  const ret = callback(div, rule);
  // Removing the fake body
  if (!body) {
    fakeBody.parentNode.removeChild(fakeBody);
    html.style.overflow = docOverflow;
  } else {
    div.parentNode.removeChild(div);
  }

  return !!ret;
}

// -----------------------------------------------------------------------
// Detecting features
// -----------------------------------------------------------------------

// Detect support of only the 2009 flexbox specification
// It's poor (e.g. no wrapping), so in many cases we don't want to use Flex
// in those
webFeatures.flex = testCSS('flexDirection', true);
if (!webFeatures.flex) {
  html.className += ' is-noFlexOr2009Only';
}

webFeatures.objectFit = testCSS('objectFit', true);
if (!webFeatures.objectFit) {
  html.className += ' is-noObjectFit';
}

if (!injectElementWithStyles(
  `#${idPrefix}{ position: sticky; }`,
  (div) => window.getComputedStyle !== undefined &&
    window.getComputedStyle(div).position === 'sticky',
)) {
  html.className += ' is-noPosSticky';
}

// -----------------------------------------------------------------------
// Determining engines/browser versions
// -----------------------------------------------------------------------

// Storing browser name and version in webFeatures.browser object.
// The classname is edited only in special cases, when needed by CSS (ALWAYS
// marked by a comment)

// Checking if the browser is of Webkit/Blink engine
// http://browserhacks.com/#hack-dee2c3ab477a0324b6a2283c434108c8 ,
// http://browserhacks.com/#hack-dbcf58b998334c91bca391a786104338
webFeatures.isWebkitBlink = !!window.chrome ||
  'WebkitAppearance' in html.style;
if (webFeatures.isWebkitBlink) {
  html.className += ' isWebkitBlink';
}

// Chrome 28-, Safari 7-, Opera 14-15, Android 4.4-
if (injectElementWithStyles(`#${idPrefix}{ (;height: 31px;); }`,
  (div) => window.getComputedStyle !== undefined &&
    window.getComputedStyle(div).height === '31px')
) {
  if (isChrome) {
    webFeatures.chrome = [0, 28];
  } else if (isSafari) {
    webFeatures.safari = [0, 7];
  } else if (isOperaBlink) {
    webFeatures.opera = [14, 15];
  } else {
    webFeatures.android = [0, 4.4];
  }
}
const selector = '@media screen { @media (min-width: 0px) { ' +
  `#${idPrefix} {height: 31px;} } } `;

// IE (no Edge), Safari 6.1-, Android 4.2-, Chrome 25-
if (injectElementWithStyles(selector, (div) => (
  // I.e., this selector works in everything but those browsers, thus
  // inverting the condition
  window.getComputedStyle !== undefined &&
  window.getComputedStyle(div).height !== '31px'))
) {
  if (isChrome) {
    webFeatures.chrome = [0, 25];
  } else if (isSafari) {
    webFeatures.safari = [0, 6.1];
  } else if (!isIE) {
    webFeatures.android = [0, 4.2];
  }
  // Webkit browsers that pass this test have a bug: % in vertical margins
  // are based not on width (as per the standard), but on height
  if (!isIE) {
    webFeatures.webkitMarginPercentBug = true;
    html.className += ' is-webkitMarginPercentBug';
  }
}

// Sometimes the webFeatures global object is not defined. Setting an extra
// breadcrumb here so that when the error occured we could see if this code
// had run at all.
if (window.Sentry) {
  window.Sentry.onLoad(() => {
    window.Sentry.setExtra('webfeautres_fired_end', true);
  });
}

window.webFeatures = webFeatures;
