/**
 * Polyfills for .matches() and .closest()
 *
 * TODO: make loading this conditional
 */

(function (w) {
  const ElementPrototype = w.Element.prototype;

  if (typeof ElementPrototype.matches !== 'function') {
    ElementPrototype.matches = ElementPrototype.msMatchesSelector ||
      ElementPrototype.mozMatchesSelector ||
      ElementPrototype.webkitMatchesSelector || function matches(selector) {
      const element = this;
      const elements = (element.document || element.ownerDocument)
        .querySelectorAll(selector);
      let index = 0;

      while (elements[index] && elements[index] !== element) {
        index += 1;
      }

      return Boolean(elements[index]);
    };
  }

  if (typeof ElementPrototype.closest !== 'function') {
    ElementPrototype.closest = function closest(selector) {
      let element = this;

      while (element && element.nodeType === 1) {
        if (element.matches(selector)) {
          return element;
        }

        element = element.parentNode;
      }

      return null;
    };
  }
}(window));
