/**
 * Having this for cases when the full Babel polyfill is not needed (only the
 * Object.assign), and we don't care for adding a little extra penalty for new
 * browsers (ads, etc). Plus, this code chunk is really extra small.
 */

if (typeof Object.assign !== 'function') {
  // Must be writable: true, enumerable: false, configurable: true
  Object.defineProperty(Object, 'assign', {
    value: function assign(target, ...rest) {
      const to = Object(target);
      let index;
      let nextSource;
      let nextKey;

      if (target === null) {
        throw new TypeError('Cannot convert undefined or null to object');
      }

      for (index = 0; index < rest.length; index++) {
        nextSource = rest[index];

        if (nextSource !== null) {
          for (nextKey in nextSource) {
            // Avoid bugs when hasOwnProperty is shadowed
            if (Object.prototype.hasOwnProperty.call(nextSource, nextKey)) {
              to[nextKey] = nextSource[nextKey];
            }
          }
        }
      }
      return to;
    },
    writable: true,
    configurable: true,
  });
}
