/* global mediaUtils */
import 'es6-promise/auto';
import FontFaceObserver from 'fontfaceobserver/fontfaceobserver.standalone';


(function (w) {
  // If the class is not there, don't need to do anything
  if (w.document.documentElement.className
    .indexOf('is-fontsNotLoaded') === -1
  ) {
    return;
  }

  if (mediaUtils.getQueryStringValue('forceFonts') === '1') {
    w.document.documentElement.classList.remove('is-fontsNotLoaded');
  }

  const fonts = [];

  // Observe 'Montserat' font for New Header
  if (w.siteSettings.headerNewEnabled) {
    fonts.push(...[
      new FontFaceObserver('Montserrat', {
        weight: 400,
      }),
      new FontFaceObserver('Montserrat', {
        weight: 500,
      }),
    ]);
  }

  fonts.push(...[
    new FontFaceObserver('Open Sans', {
      weight: 400,
    }),
    new FontFaceObserver('Open Sans', {
      weight: 400,
      style: 'italic',
    }),
    new FontFaceObserver('Open Sans', {
      weight: 700,
    }),
    new FontFaceObserver('Rokkitt', {
      weight: 600,
    }),
  ]);

  Promise.all(fonts.map((font) => font.load()))
    .then(() => {
      w.document.documentElement.classList.remove('is-fontsNotLoaded');
    },
    () => {
      w.console.warn('Failed to load one or more web-fonts!');
    });
}(window));
