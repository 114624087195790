const utils = {
  // search parameter by key in string
  _getStringValue(str, key) {
    return unescape(str.replace(
      new RegExp(`^(?:.*[&\\?]${escape(key).replace(/[\.\+\*]/g, '\\$&')}` +
        '(?:\\=([^&]*))?)?.*$', 'i'), '$1',
    ));
  },

  // search parameter by name in query string
  getQueryStringValue(key) {
    return this._getStringValue(window.location.search, key);
  },

  // search parameter by name in hash
  getHashValue(key) {
    return this._getStringValue(window.location.hash, key);
  },

  /**
   * Parses a URL
   *
   * @param {String} url - A string to parse
   *
   * @return {Object} obj - an object with the url's params, {param: value}
   */
  parseUrl(url) {
    let urlLocal = url;
    const result = {};
    let temp;
    if (typeof (urlLocal) !== 'string') { return false; }
    if (urlLocal.trim() === '') { return result; }
    if (urlLocal.includes('?')) {
      urlLocal = urlLocal.substr(urlLocal.indexOf('?') + 1);
    }
    const arr = urlLocal.split('&');
    arr.forEach((el) => {
      temp = el.split('=');
      result[temp[0]] = decodeURIComponent(temp[1]);
    });
    return result;
  },

  /**
   * Builds a query string based on an object's keys and values
   * Uses encodeURIComponent to properly escape parameters
   *
   * @param {Object} obj - an object to build a query string from
   *
   * @return {String} query string, '?param1=value1&param2=value2...'
   */
  buildQueryString(obj) {
    const result = Object.keys(obj).reduce((prev, current) => {
      const value = typeof (obj[current]) === 'object' ?
        JSON.stringify(obj[current]) : obj[current];
      return `${prev + current}=${encodeURIComponent(value)}&`;
    }, '?');
    return result.substring(0, result.length - 1);
  },

  /**
   * Check if cookie allowed in the user's browser
   */
  _isCookieAllowed() {
    const cookieAllowed = 'cookie' in document &&
      (document.cookie.length > 0 || (document.cookie = 'test')
        .indexOf.call(document.cookie, 'test') > -1);
    return cookieAllowed;
  },

  get isCookieAllowed() {
    return this._isCookieAllowed();
  },

  /**
   * Checks if cookie exists
   *
   * @param {any} name - Cookie name
   * @param {any} value - Cookie value; if not set, check if not empty
   *
   * @return {bool|string} true, if the cookie is AND its value is `value`;
   *   if `value` is not set, returns cookie value or false if it is empty
   */
  checkCookie(name, value) {
    if (name === undefined) { return false; }

    const valReg = new RegExp(`(?:^|;)(?:\\s)*${name}=(.*?)(?:;|$)`);
    const match = document.cookie.match(valReg);

    if (match) {
      if (value !== undefined) {
        return match[1] === value.toString();
      }
      return match[1] !== '' ? match[1] : false;
    }
    return false;
  },
  /**
   * Sets a cookie
   *
   * @param {any} name - Cookie name (funciton converts it to String)
   * @param {any} value - Cookie value (funciton converts it to String)
   * @param {string|num} expires - Expire date (if String) or max-age
   *  (if a number). If not set, expires is set to 2038
   * @param {string} path - Cookie path. If not specified, sets '/'
   *
   * @return {bool|string} false if name or value not specified,
   *  or a cookie set
   */
  setCookie(name, value, expires, path) {
    let cookie;

    if (name === undefined || value === undefined) { return false; }

    cookie = `${name}=${value}; path=${path !== undefined ? path : '/'}`;

    if (expires === undefined || !expires) {
      cookie += '; expires=Tue, 19 Jan 2038 03:14:07 GMT';
    } else if (typeof (expires) === 'string') {
      cookie += `; expires=${expires}`;
    } else if (typeof (expires) === 'number') {
      cookie += `; max-age=${expires}`;
    }

    document.cookie = cookie;
    return cookie;
  },
  /**
   * Deletes a cookie
   *
   * @param {any} name - Cookie name (funciton converts it to String)
   *
   * @return {bool} false, if name is not specified, true otherwise
   */
  delCookie(name, options) {
    if (name === undefined) { return false; }

    // Set `expires` to a past date, effectively making a cookie outdated
    let newValue = `${name}=1;expires=Fri, 1 Jan 1982 03:14:07 GMT;`;
    if (options?.path) {
      newValue += `path=${options.path};`;
    }
    if (options?.domain) {
      newValue += `domain=${options.domain};`;
    }
    document.cookie = newValue;
    return true;
  },

  checkLocalStorage(name, value) {
    return (window.localStorage !== undefined && localStorage !== null &&
      localStorage.getItem(name) === value);
  },

  checkSessionStorage(name, value) {
    return (window.sessionStorage !== undefined && sessionStorage !== null &&
      sessionStorage.getItem('seen_veteran_web_modal') === value);
  },

  /**
   * Scrolls the document to the top edge of the specified element
   * TODO: not sure if it has to be here (basically in head.js)
   *
   * @param {Node} element - the html element we need to scroll to
   * @param {Number} options.duration - (optional) the scrolling duration
   * @param {Boolean} options.ignoreIfVisible - (optional) set to true if no
   *  scrolling is needed if the element (its top edge) is already visible.
   * @param {Function} options.callback - (optional) a function to run
   *  afterwards
   *
   * @return {Boolean} return - false if no scroll has been triggered
   */
  scrollTo(element, options = {
    duration: 300,
    ignoreIfVisible: false,
    callback: null,
  }) {
    const { duration, ignoreIfVisible, callback } = options;
    const header = document.querySelector('#header'); // TODO: upd
    // Taking into account that the Header can be sticky
    const elementPosition = element.getBoundingClientRect().top - 10 -
      ((header && window.getComputedStyle(header)?.position === 'sticky') ?
        header.clientHeight : 0);

    const scrollNewPosition = elementPosition + window.pageYOffset;
    let callbackCallFlag = false;

    if (elementPosition > 0 && ignoreIfVisible) { return false; }

    if (!window.siteState) { window.siteState = {}; }
    // Telling any code that do something on scroll that this scroll is
    // artifical
    window.siteState.artificalScrollBlock = true;

    if (window.$) {
      // Scrolling the document to the appropriate position
      window.$('html, body').animate({ scrollTop: scrollNewPosition },
        duration,
        () => {
          // To not fire the callback twice - for the html and for the body
          if (!callbackCallFlag) {
            callbackCallFlag = true;
          } else {
            if (callback) { callback(); }
            window.siteState.artificalScrollBlock = false;
          }
        });
    } else {
      // TODO: this can scroll smoothly! No jquery animations needed! We just
      // need a graceful degradation for browsers that don't support the options
      // object
      window.scrollTo(0, scrollNewPosition);
      if (callback) { callback(); }
      window.siteState.artificalScrollBlock = false;
    }

    return true;
  },

  /**
   * Send data to Lytics
   *
   * @param {Object} formData - the data to send
   * @param {Function} callback - will run after the data is sent
   *
   * @return {Boolean|Object} false if the message wasn't sent (because of
   *    the jstag object missing, or because it shouldn't be sent);
   *    the message object, if lio is not ready yet and the message is put
   *    in a query (to be sent later when lio is loaded);
   *    true - if the data is sent immediately
   */
  sendLyticsData(formData, callback) {
    let result;
    let key;

    // `jstag` is defined in our code. `jstag.send()` puts a data object to
    // a query, and sends the whole query when the `lio` object is ready
    // (loaded externally). If lio is already loaded when send() is called,
    // the data is sent immediately. So we don't return false here if lio
    // is not ready because it might be not ready YET.
    if (window.jstag === undefined || window.sendLyticsDataEnabled !== true
    ) {
      return false;
    }

    const data = {
      form_id: `${window.location.protocol}//${window.location.host}` +
        `${window.location.pathname}`,
    };

    for (key in formData) {
      if (!Object.prototype.hasOwnProperty.call(formData, key)) {
        continue;
      }

      switch (key) {
        case 'first_name':
        case 'first':
          data.firstName = formData[key];
          break;
        case 'last_name':
        case 'last':
          data.lastName = formData[key];
          break;
        case 'organization_name':
        case 'department':
          data.department = formData[key];
          break;
        case 'zip':
          data.zipCode = formData[key];
          break;
        case 'email':
        case 'state':
        case 'position':
        case 'phone':
        case 'city':
          data[key] = formData[key];
          break;
        default:
          break;
      }
    }

    if (typeof callback === 'function') {
      result = window.jstag.send(data, callback);
    } else {
      result = window.jstag.send(data);
    }

    return typeof result === 'object' ? result : true;
  },

  /**
   * Injects script into the document HEAD
   * This is used e.g. to inject Polyfill.js, when it's needed.
   *
   * @param {String} src - the script source
   * @param {funcion} onLoad function - (optional) the callback that execute
   *   after script will be loaded (or not)
   */
  injectScript(src, onLoad) {
    const js = document.createElement('script');
    js.src = src;
    if (onLoad) {
      js.onload = (e) => {
        onLoad(e);
      };
      js.onerror = (e) => {
        onLoad(e, new Error(`Failed to load script ${src}`));
      };
    }
    document.head.appendChild(js);
  },

  /**
   * Injects a link into the document HEAD
   *
   * @param {String} href - the link href
   */
  injectLink(href) {
    const link = document.createElement('link');
    link.href = href;
    link.rel = 'stylesheet';
    document.head.appendChild(link);
  },

  /**
   * @brief Safely runs a DOMContentLoaded event callback
   *
   * @param [in] callback - a callback function
   */
  domReady(callback) {
    if (document.readyState === 'loading') {
      document.addEventListener('DOMContentLoaded', callback);
    } else {
      callback();
    }
  },

  /**
   * @brief Sending data to GA
   *
   * @param [in] data - the fieldsObject object
   */
  trackWithGa(initialData) {
    const data = initialData;
    if (!window.ga || !window.ga.getAll) { return; }

    const tracker = window.ga.getAll()[0];
    if (!tracker) { return; }
    if (!data.hitType) { data.hitType = 'event'; }
    tracker.send(data);
  },
};

// For standalone scripts that we export
export default utils;

// Internally we'll be storing the object in the global scope 1) to avoid
// importing it in every single bundle on a site, and 2) to be able to use
// the helpers in inline scirpts.
window.mediaUtils = utils;
