import device from 'device.js/';


const DetectDeviceHelper = window.DetectDeviceHelper || {};

DetectDeviceHelper.detectDevice = () => {
  if (device !== null) {
    if (device.tablet()) {
      return 'tablet';
    }
    if (device.mobile()) {
      return 'mobile';
    }
    if (device.desktop()) {
      return 'desktop';
    }
  }
  return '';
};

DetectDeviceHelper.isDesktop = () => device && device.desktop();

DetectDeviceHelper.isMobile = () => device && device.mobile();

DetectDeviceHelper.isTablet = () => device && device.tablet();

window.DetectDeviceHelper = DetectDeviceHelper;
